import { Menu } from "../controles/Menu";
import React, { useState, useRef, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { __GetSessionID } from '../controles/Session';
import { useNavigate } from "react-router-dom";


import { DBProductos, Marcas } from "../DBProductos";
import { eFldType, Formater, DropDown, Alerta, ResourceName } from "../controles/Utilidades";

export const Carrito = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState(false);
    const [direcciones, setDirecciones] = useState([]);
    const [entregaren, setEntregaren] = useState({});
    const [productos, setProductos] = useState([]);
    const [myparams, setMyparams] = useState({ total: 0.00, hastels: false, hascarrito: false });
    const rfDirSel = useRef('');
    const rfName = useRef('');
    const rfTel = useRef('');

    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());

        const res = await fetch("cproductos/VerPedido", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            if (va.login === '1') { setLogin(true); }
            else { setLogin(false); }
            setProductos(FillProductos(va.carrito))
            setMyparams({ ...myparams, total: va.total, hascarrito: va.hascarrito === '1', hastels: va.hastels === '1' });
            setDirecciones(va.direcciones);
            setEntregaren(va.entregaren);
        } else {
            const asd = await res.text();
            alert(asd);
        }

    }
    const API_DelProd = async (item) => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', JSON.stringify({ item: item }));
        const res = await fetch("cproductos/DelProd", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setProductos(FillProductos(va.carrito))
            setMyparams({ ...myparams, total: va.total, hascarrito: va.hascarrito === '1' });

        } else {
            const asd = await res.text();
            alert(asd);
        }

    }
    const API_GetDir = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', rfDirSel.current.value);
        const res = await fetch("cproductos/GetDir", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setEntregaren(va.entregaren);

        } else { alert(await res.text()); }
    }
    const API_SavePedido = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', rfDirSel.current.value);
        data.append('name', rfName.current.value);
        data.append('tel', rfTel.current.value);
        const res = await fetch("cproductos/SavePedido", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();

            navigate(`/terminado/${va.folio}/${va.total}`);

        } else { Alerta(await res.text()); }
    }

    //funciones
    const FillProductos = (carrito = []) => {
        let salida = [];

        carrito.forEach(car => {
            let prod = DBProductos.find(y => y.codprod === car.codprod);
            if (prod !== null && prod !== undefined) {
                let vari = prod.variant.find(z => z.id === car.variantid);
                if (vari !== null && vari !== undefined) {
                    vari.precio = car.precio;
                    salida.push({
                        uidvta: car.uidvta,
                        cant: car.cant,
                        codprod: prod.codprod,
                        nombre: prod.nombre,
                        descrip: prod.descrip,
                        marsel: vari.marca,
                        marca: ResourceName(vari.marca, Marcas),
                        desc: car.desc,
                        importe: car.importe,
                        variant: vari
                    });
                }
            }

        })
        return salida;
    }
    const onDelItem = (item) => {
        if (item === undefined || item === '') { return; }
        API_DelProd(item);
    }
    const onAddDir = () => {
        navigate('/newdir');
    }
    const onChangeDir = () => {
        if (rfDirSel.current.value === '') { return; }
        API_GetDir();
    }
    const onSave = () => {
        if (login) {
            if (rfDirSel.current.value === '') { Alerta('Seleccione una direccion de entrega'); return; }
        } else {
            if (rfName.current.value === '') { Alerta('Escriba un nombre'); return; }
            if (rfTel.current.value === '') { Alerta('Escriba un numero de telefono'); return; }
        }
          API_SavePedido();
    }

    //EVENTOS
    useEffect(() => {
        API_Init();
    }, []);
    return (
        <>
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <br />
                <Row lg={2} className="centrar">
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Tu pedido</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {myparams.hascarrito &&
                                    <>
                                        {!login ?
                                            <>
                                                <Card.Title> <a href="/login">Inicia sesi&oacute;n</a></Card.Title>
                                                <Card.Title>&oacute;</Card.Title>
                                                <Card.Title> Completa la siguiente informaci&oacute;n</Card.Title>
                                                <label>Y te enviaremos un WhatsApp del numero (33 3105 1948), para confirmar existencias y tiempos de entrega</label>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Nombre</Form.Label>
                                                    <Form.Control type="text" ref={rfName} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Telefono</Form.Label>
                                                    <Form.Control type="text" ref={rfTel} />
                                                </Form.Group>
                                            </>
                                            :
                                            <>
                                                <div className="flex flexAE">
                                                    <div>
                                                        <Card.Text>
                                                            Direccion de entrega
                                                        </Card.Text>
                                                    </div>
                                                    <div>
                                                        <Button variant="primary" onClick={onAddDir}>Agregar</Button>
                                                    </div>
                                                </div>
                                                {entregaren.calle !== undefined &&
                                                    <>
                                                        <div className="mb-3" >
                                                            <DropDown drpRef={rfDirSel} data_key_value={direcciones} onchange={onChangeDir} />
                                                        </div>
                                                        <Card.Header>
                                                            <div className="d-flex justify-content-between align-items-start">
                                                                <div className="flex">
                                                                    <div className="ms-2 me-auto">
                                                                        <div className="fw-bold">
                                                                            {entregaren.calle}&nbsp;{entregaren.numero}&nbsp;{entregaren.numint}
                                                                        </div>
                                                                        {entregaren.colonia.length > 0 &&
                                                                            <div>
                                                                                Colonia&nbsp;{entregaren.colonia}
                                                                            </div>
                                                                        }
                                                                        {entregaren.cpostal.length > 0 &&
                                                                            <div>
                                                                                CP&nbsp;{entregaren.cpostal}&nbsp;&nbsp;&nbsp;&nbsp;{entregaren.ciudad}&nbsp;{entregaren.estado}
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Header>
                                                    </>
                                                }
                                                {!myparams.hastels &&
                                                    <>
                                                        <br />

                                                        <Alert variant="danger">
                                                            No tiene telefonos registrados
                                                            <br />
                                                            <small className="text-muted">Registre un telefono en perfil para comunicarnos en caso de algun problema con su pedido</small>
                                                        </Alert>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }


                                <br />
                                <hr />
                                <br />
                                {!myparams.hascarrito ?
                                    <Card.Title> No hay art&iacute;culos en tu pedido</Card.Title>
                                    :
                                    <ListGroup as="ul" >
                                        {productos.map((item) => (
                                            <ListGroup.Item
                                                key={item.uidvta}
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                            >
                                                <div className="flex">
                                                    <div>
                                                        <Badge bg="primary" pill>{item.cant}</Badge>
                                                    </div>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{item.nombre}&nbsp;{item.marca}</div>
                                                        <div>
                                                            {item.variant.nombre}
                                                        </div>
                                                        <div>
                                                            Precio:&nbsp;<Formater fldtype={eFldType.Currency} text={item.variant.precio} />
                                                        </div>
                                                        {item.desc > 0 &&
                                                            <div>
                                                                Descuento:&nbsp;<Formater fldtype={eFldType.Currency} text={item.desc} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="lbtitle text-success"><Formater fldtype={eFldType.Currency} text={`${item.importe}`} /></label>
                                                    <Badge onClick={() => { onDelItem(item.uidvta); }} className="pointer m-2" bg="secondary" pill>X Quitar</Badge>
                                                </div>
                                            </ListGroup.Item>
                                        ))
                                        }


                                    </ListGroup>
                                }

                                <br />
                                <div className="flex flexAE">

                                    <div>
                                        {myparams.hascarrito &&
                                            <Button variant="warning" onClick={onSave}>Finalizar Pedido</Button>
                                        }
                                    </div>
                                    <div>
                                        <label className="lbtitle text-success">Total:</label>
                                        <label className="lbtitle text-success"> &nbsp;<Formater fldtype={eFldType.Currency} text={`${myparams.total}`} /></label>
                                    </div>
                                </div>

                            </Card.Body>
                            <Card.Footer>
                                <div>
                                    <small className="text-muted">Sujeto a disponibilidad</small>
                                </div>
                                <div>
                                    <small className="text-muted">Si tienes alguna duda puedes contactarnos al 33 3105 1948</small>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </>

    )
}