/*

UTILIDADES VERSION PARA REACT-BOOTSTRAP
no maneja todos los componentes que el utilidades de CRPOS
porque no se manejan los mismos objetos 

usar esta verion solo en proyectos con react-boottrap


                                    VER  1.0.1


*/



import { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { __ResetSession } from '../controles/Session';


//LISTAS DE RECURSOS---------------------------------------------------------------------------------------
//Deben coincidir con los de la api  
export const eTypeCli = [{ key: "0", value: "Persona Fisica" }, { key: "1", value: "Persona Moral" }]
export const eStatusCliente = [{ key: "0", value: "Activo" }, { key: "2", value: "Bloqueado" }]
export const eFldType = { String: 0, DateTime: 1, Currency: 2, Ingeter: 3, Date: 4, Decimal0: 6, Resource: 5, Cantidad: 7 }//deben coincidir con los del GrnTable.cs
export const constantes = { decimales_Stock: 0 }
export const ResTypePhones = [{ key: '1', value: 'Celular' }, { key: '2', value: 'Fijo' }];

//---------------------------------------------------------------------------------------componentes---------------------------------------------------------------------------------------
export const Tronar = ({ api = "chome/DelSession", sendTo = "/" }) => {
    //api= api de donde esta la funcion de delsession
    __ResetSession();

    const API_Exit = async () => {
        fetch(api, { method: 'GET' });
    }

    //funciones

    //EVENTOS
    useEffect(() => {
        API_Exit();
    }, []);//init
    return (<Navigate replace to={sendTo} />)
}
export const Formater = ({ fldtype, text, resourceKV = [] }) => {
    switch (fldtype) {
        default:
        case eFldType.String:
            return text;
        case eFldType.DateTime:
            return FormatDT(text);
        case eFldType.Date:
            return FormatDate(text);
        case eFldType.Cantidad:
            return FormatDecimal(text, constantes.decimales_Stock);
        case eFldType.Decimal0:
            return FormatDecimal(text, 0);
        case eFldType.Currency:
            return ToCur.format(text);
        case eFldType.Resource:
            return ResourceName(text, resourceKV);
    }
}
export const RandomInt = (maxVal) => {
    return Math.floor(Math.random() * maxVal);
}
//---------------------------------------------------------------------------------------COMPONENTES---------------------------------------------------------------------------------------
export const TabBar = ({ tabs = [{ text: '', tabclick: null }] }) => {
    const [active, setActive] = useState('');
    const onPress = (item) => {
        setActive(item.text);
        if (item.tabclick !== null) { item.tabclick(); }
    }
    return (
        <>
            {
                tabs.map((item, index) => (
                    <div key={index} className={`tab ${active === item.text ? 'actived' : ''}`} onClick={() => { onPress(item); }} >
                        {item.text}
                    </div>
                ))
            }
        </>

    )
}
export const TextBox = ({ lbText = "", txtRef, lbClas = "", txtType = "text", txtClass = "", children, txtmain = "txtl", divClas = "", onkeydown }) => {

    return (
        <div className={`m-1 flex flexAC ${divClas}`}>
            <label className={`mr-7 ${lbClas}`}>{lbText}</label>
            <input className={`${txtmain} ${txtClass}`} type={txtType} ref={txtRef} onKeyDown={onkeydown} />
            {children}
        </div>
    )
}
export const LabelValue = ({ lbText = "", valText = "", lbClas = "lbsubtitle", lbColor = "text-white", valColor = "text-warning", valClass = "lbsubtitle", valFormat = eFldType.String, divClas = "" }) => {

    return (
        <div className={`m-1 flex flexAC ${divClas}`}>
            <label className={`mr-7 ${lbClas} ${lbColor}`}>{lbText}</label>
            <label className={`mr-7 ${valClass} ${valColor}`}><Formater fldtype={valFormat} text={valText} /></label>
        </div>
    )
}
export const DropDown = ({ lbText = "", drpRef, lbClas = "", data_key_value = [], onchange = undefined }) => {
    //funciones
    const onChange = () => {
        if (onchange === undefined) { return; }
        onchange();
    }
    ////EVENTOS
    //useEffect(() => {
    //    if (valSelected.length !== 0 && drpRef !== undefined) {
    //        drpRef.current.value = valSelected;
    //    }
    //}, []);//init
    return (
        <div className="m-1 flex flexH flexAC ">
            {lbText && <label className={`mr-7 ${lbClas}`}>{lbText}</label>}
            <div className="drp">
                <select ref={drpRef} onChange={() => { onChange() }}>
                    {data_key_value.map((item, index) => (<option key={index} value={item.key}>{item.value}</option>))}
                </select>
            </div>
        </div>
    )
}
export const DropDyn = ({ lbText = "",  lbClas = "", data_key_value = [], onchange = undefined,  id= '',initValue }) => {
    const rfdrp = useRef();
    // id es requerido

    //funciones
    const onChange = () => {
        if (onchange === undefined) { return; }
        onchange(id, rfdrp.current.value);
    }
    ////EVENTOS
    useEffect(() => {
        if (initValue !== undefined) {
            rfdrp.current.value = initValue;
        }
    }, []);//init
    return (
        <div className="m-1 flex flexH flexAC ">
            {lbText && <label className={`mr-7 ${lbClas}`}>{lbText}</label>}
            <div className="drp">
                <select ref={rfdrp} onChange={() => { onChange() }}>
                    {data_key_value.map((item, index) => (<option key={index} value={item.key}>{item.value}</option>))}
                </select>
            </div>
        </div>
    )
}
export const MessageBox = ({ onclickYes, onclickNo, tittle, subtittle, text = '' }) => {

    const OnClickYes = () => {
        if (onclickYes === null || onclickYes === undefined) { return; }
        onclickYes();
    }
    const OnClickNo = () => {
        if (onclickNo === null || onclickNo === undefined) { return; }
        onclickNo();
    }
    return (
        <>
            <div className="cardl-green  col-lg-5">
                <label className="lbtitle text-white">{tittle}</label>
                <p>
                    <label className="lbsubtitle" >{subtittle}</label>
                </p>
                {text.length > 0 &&
                    <div className="row">
                        <div className="m-2">
                            <label htmlFor="txtText" className="text-white">{text}</label>
                        </div>
                    </div>
                }
                <br />
                <div className="flex flexAE">
                    <div className="col-lg-3 m-2">
                        <button onClick={() => { OnClickNo(); }} className="btnl-red w100">No</button>
                    </div>
                    <div className="col-lg-3 m-2">
                        <button onClick={() => { OnClickYes(); }} className="btnl-green w100">Si</button>
                    </div>
                </div>
            </div>
        </>

    )
}
export const CheckBox = ({ id, text, addRef, checked = false }) => {

    useEffect(() => {
        addRef.current.checked = checked;
    }, []);//init

    return (
        <div className="m5" style={{ display: "inline-flex" }}>
            <input className="cbx" id={id} ref={addRef} style={{ display: "none" }} type="checkbox"></input>
            <label className="toggle mt-2" htmlFor={id} ><span></span></label>
            <label className="text-white h5 m-1">{text}</label>
        </div>
    )
}
export const ResultBox = ({ setventSel, children }) => {
    const _defvtmod = { eVent: 0, param: null };

    return (
        <>
            <div className="cardl-white col-lg-5" >
                {children}
                <br />
                <button className="btnl-green" onClick={() => { setventSel(_defvtmod); }} >Cerrar</button>
            </div>
        </>
    )
}
export const DateRange = ({ refInit, refEnd }) => {

    return (

        <div style={{ display: "grid", gridTemplateColumns: "53px 2fr", alignItems: "center" }}>
            <label>DE   </label>
            <input className="form-control m-1" type="datetime-local" style={{ maxWidth: 230 }} ref={refInit} />
            <label>HASTA</label>
            <input className="form-control m-1" type="datetime-local" style={{ maxWidth: 230 }} ref={refEnd} />
        </div>
    )
}
export const Alerta = (text) => {
    const modal = document.querySelector("[data-modal]");
    const foo = document.querySelector("[data-modal-content]");
    foo.innerHTML = text;
    modal.showModal();

}

//---------------------------------------------------------------------------------------utilerias---------------------------------------------------------------------------------------
export const IsValidEmail = (value) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return value.match(validRegex);
}
export function ResourceName(text, resKV) {
    if (text === undefined || text === null || text.length === '') { return ''; }
    if (resKV === undefined || resKV === null || resKV.length === 0) { return ''; }
    const item = resKV.find(x => x.key === text);
    if (item === undefined) { return ''; }
    return item.value;
}
const ToCur = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigit: 2 });
export const ToFloat = (a) => parseFloat(a);
function FormatDecimal(text, idecimals) {
    if (typeof (text) === 'number') { text = text.toString(); }
    if (text.indexOf(".") === -1) { return text; }
    if (idecimals === 0) {
        return text.substring(0, text.indexOf("."));
    }
    var foo = text.substring(0, text.indexOf(".") + idecimals + 1);
    return foo;
}
export const FormatDT = (date) => {//de vuelve 2022-12-23 16:34:50 formato
    if (date.length === 0) { return ""; }
    return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}  ${date.substring(8, 10)}:${date.substring(10, 12)}:${date.substring(12, 14)}`;
}
function FormatDate(date) {//de vuelve 2022-12-23 formato
    if (date.length === 0) { return ""; }
    return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
}
export const ContainSymbols = (str) => {
    const specialChars = /[` !@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str.trim());
}
export const RandomString = (length) => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

