import { useEffect, useState, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Menu } from "../controles/Menu";
import { __GetSessionID } from '../controles/Session';
import Button from 'react-bootstrap/Button';
import { Tronar, Alerta } from "../controles/Utilidades.jsx"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';


export const NewDir = () => {
    const { edit } = useParams();
    const [authorize, setAuthorize] = useState(true);
    const navigate = useNavigate();

    const rfCalle = useRef('');
    const rfNoExt = useRef('');
    const rfNoInt = useRef('');
    const rfCpostal = useRef('');
    const rfColonia = useRef('');
    const rfCiudad = useRef('');
    const rfEstado = useRef('');
    const rfNombre = useRef('');

    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', edit);
        const res = await fetch("cuser/GetDir", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            rfCalle.current.value = va.direccion.calle;
            rfNoExt.current.value = va.direccion.numero;
            rfColonia.current.value = va.direccion.colonia;
            rfCiudad.current.value = va.direccion.ciudad;
            rfCpostal.current.value = va.direccion.cpostal;
            rfEstado.current.value = va.direccion.estado;
            rfNombre.current.value = va.direccion.alias;
            rfNoInt.current.value = va.direccion.numint;
        } else if (res.status === 401) { setAuthorize(false); } else { alert(await res.text()); }
    }
    const API_Save = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('edit', edit === undefined?'':edit);
        data.append('value', JSON.stringify({
            calle: rfCalle.current.value,
            noext: rfNoExt.current.value,
            colonia: rfColonia.current.value,
            ciudad: rfCiudad.current.value,
            cpostal: rfCpostal.current.value,
            estado: rfEstado.current.value,
            nombre: rfNombre.current.value,
            noint: rfNoInt.current.value,
        }));
        const res = await fetch("cuser/NewDir", { method: 'POST', body: data });
        if (res.ok) {
            //            const va = await res.json();
            navigate('/perfil');

        } else if (res.status === 401) { setAuthorize(false); } else { Alerta(await res.text()); }
    }


    //funciones 
    const onSave = () => {
        if (
            rfNombre.current.value === '' ||
            rfCalle.current.value === '' ||
            rfNoExt.current.value === '' ||
            rfCpostal.current.value === '' ||
            rfColonia.current.value === '' ||
            rfCiudad.current.value === '' ||
            rfEstado.current.value === ''
        ) { Alerta('Complete los datos obligatorios'); return; }
        API_Save();
    }

    //EVENTOS
    useEffect(() => {
        if (edit === undefined) { return; }
        API_Init();
    }, []);//init

    return (
        <>
            {!authorize && <Tronar />}
            <Menu ></Menu>
            <br />
            <br />
            <br />
            <Container >
                <Row lg={2} className="centrar">
                    <Col lg={6}>
                        <Card border="primary">
                            <Card.Header>
                                <Card.Title className="text-blue" >Agregar nueva direccion de entrega</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3"  >
                                            <Form.Label>Nombre/Alias</Form.Label>
                                            <Form.Control type="text" ref={rfNombre} placeHolder="Obligatorio" />
                                        </Form.Group>
                                    </Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Calle</Form.Label>
                                        <Form.Control type="text" ref={rfCalle} placeHolder="Obligatorio" />
                                    </Form.Group>
                                    <div className="flex flexH">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Num exterior</Form.Label>
                                            <Form.Control type="text" ref={rfNoExt} placeHolder="Obligatorio" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Num Interior</Form.Label>
                                            <Form.Control type="text" ref={rfNoInt} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Codigo Postal</Form.Label>
                                            <Form.Control type="text" ref={rfCpostal} placeHolder="Obligatorio" />
                                        </Form.Group>
                                    </div>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Colonia</Form.Label>
                                        <Form.Control type="text" ref={rfColonia} placeHolder="Obligatorio" />
                                    </Form.Group>
                                    <div className="flex flexH">
                                        <Form.Group className="mb-3 col-lg-6" >
                                            <Form.Label>Ciudad</Form.Label>
                                            <Form.Control type="text" ref={rfCiudad} placeHolder="Obligatorio" />
                                        </Form.Group>
                                        <Form.Group className="mb-3 col-lg-6" >
                                            <Form.Label>Estado</Form.Label>
                                            <Form.Control type="text" ref={rfEstado} placeHolder="Obligatorio" />
                                        </Form.Group>
                                    </div>

                                    <Form.Group className="mb-3" >
                                        <Button variant="success" onClick={() => { onSave() }}>Guardar</Button>
                                    </Form.Group>
                                </Form>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
