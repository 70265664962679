import {  RandomString } from '../controles/Utilidades';

//--------------------------------------------------------manejo de sesion--------------------------------------------------------------

const _defSession = { id: RandomString(16), username: 'Iniciar Sesi\u00F3n', login: false, panel: 'home' }

export const __GetSession = () => {
    if (localStorage.biohig === undefined) {
        localStorage.biohig = JSON.stringify(
            {
                id: RandomString(16),
            })
    }
    return JSON.parse(localStorage.biohig);
}
export const __ResetSession = () => {
    localStorage.biohig = JSON.stringify(_defSession);
}
export const __GetSessValue = (key) => {
    let storage;
    if (localStorage.biohig === undefined) {
        localStorage.biohig = JSON.stringify(_defSession);
        storage = _defSession;
    } else {
        storage = JSON.parse(localStorage.biohig);
    }
    return storage[key];
}
export const __SetSessValue = (key, value) => {
    let storage;
    if (localStorage.biohig === undefined) {
        storage = _defSession;
    }
    else {
        storage = JSON.parse(localStorage.biohig);
    }

    storage[key] = value;
    localStorage.biohig = JSON.stringify(storage);
}
export const __GetSessionID = () => {
    if (localStorage.biohig === undefined) {
        localStorage.biohig = JSON.stringify(_defSession);
    }
    //return __GetSession().id;
    return __GetSession().id;
}
//<>