import { Menu } from "../controles/Menu";
import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { __GetSessionID } from '../controles/Session';

import { Alerta } from '../controles/Utilidades';


export const Login = () => {
    const [runMenu, setRunMenu] = useState(false);
    const rfUser = useRef('');
    const rfPwd = useRef('');
    const navigate = useNavigate();
    //llamadas 
    const API_Init = async () => {
        const res = await fetch("chome/Init", { method: 'GET' });
        if (res.ok) {
            //  const va = await res.json();
            setRunMenu(true);
        } else if (res.status === 401) { /*__ResetSession();*/ setRunMenu(true); } else { alert(await res.text()); setRunMenu(true); }
    }
    const API_Login = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('username', rfUser.current.value);
        data.append('password', rfPwd.current.value);

        const res = await fetch("chome/Login", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            //__SetSessValue('username', va.username);
            //__SetSessValue('login', true);
            //__SetSessValue('panel', va.page);
            navigate('/home');
        } else {
            const asd = await res.text();
            Alerta(asd);
        }
    }


    //funciones
    const onbtnClick = () => {
        //__SetSessValue('username','pedro');
        if (rfUser.current.value === '' || rfPwd.current.value === '') { Alerta('Complete la informacion'); return; }
        API_Login();
    }
    //EVENTOS
    useEffect(() => {
      //  API_Init();
    }, []);//init




    //useEffect(() => { API_Test(); }, [])

    return (
        <>
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <br />
                <Row xs={1} md={2} lg={12} className="justify-content-md-center">
                    <Col >
                        <Card >
                            <Card.Body>
                                <label className="lbtitle text-success">Inicio de Sesion</label>
                                <br />
                                <br />
                                <Card.Subtitle className="mb-2 text-muted">Bienvenido a </Card.Subtitle>
                                <Card.Subtitle className="mb-2 text-muted">BIO Comercial Limpieza e higiene</Card.Subtitle>
                                <br />
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Correo</Form.Label>
                                        <Form.Control type="email" placeholder="Ingresa email" ref={rfUser} />
                                        {/*<Form.Text className="text-muted">*/}
                                        {/*    We'll never share your email with anyone else.*/}
                                        {/*</Form.Text>*/}
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" ref={rfPwd} />
                                    </Form.Group>

                                    <Button variant="primary" onClick={() => { onbtnClick(); }} >Entrar</Button>
                                </Form>
                                <br />
                                
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    )
}
