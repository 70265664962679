import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './custom.css';

import { Home } from './components/Home';
import { Productos } from './components/Productos';
import { Carrito } from './components/Carrito';
import { Login } from './components/Login';
import { Perfil } from './components/Perfil';
import { NewDir } from './components/NewDir';
import { Terminado } from './components/Terminado';
import { Pedidos} from './components/Pedidos';


const App = () => {
    document.body.classList.add('fondo');

    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/carrito" element={<Carrito />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/perfil" element={<Perfil/>} />
                    <Route path="/newdir" element={<NewDir/>} />
                    <Route path="/pedidos" element={<Pedidos/>} />
                    <Route path="/terminado/:folio/:total" element={<Terminado/>} />
                    <Route path="/newdir/:edit" element={<NewDir/>} />
                    <Route path="/productos/:filfam/:filmarca/:filname" element={<Productos />} />
                </Routes>
            </div>
        </Router>
    )


}

export default App;

