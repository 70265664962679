import { Menu } from "../controles/Menu";
import React, { useState, useRef, useEffect } from 'react'
import { useParams } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';

import { Formater, eFldType, DropDyn } from '../controles/Utilidades';
import { __GetSessionID } from '../controles/Session';


import { DBProductos, Marcas } from "../DBProductos";
//import img_No from "../img/noimage.png";
const FiltrarProds = (filfam, filmarca, filname) => {

    let productos = DBProductos;
    if (filfam !== '0') {
        productos = productos.filter(x => x.familia === filfam);
    }
    if (filmarca !== '0') {
        productos = productos.filter(x => x.marcas.some(y => y === filmarca));
    }
    if (filname !== '*') {
        productos = productos.filter(x => x.nombre.includes(filname));
    }
    return productos;
}

export const Productos = () => {

    const { filfam, filmarca, filname } = useParams();
    const eVentModal = { none: 0, AddProd: 1 };
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ vent: eVentModal.none, param: null });

    const [productos, setProductos] = useState(FiltrarProds(filfam, filmarca, filname));

    //funciones
    const handleCloseModal = () => setShowModal(false);
    const onMarcaChange = (id, value) => {
        productos.find(x => x.codprod === id).marsel = value;
        setProductos(FiltrarProds(filfam, filmarca, filname));
    }
    const onclickprod = (prod, vari) => {
        setModalContent({ vent: eVentModal.AddProd, param: { prod: prod, vari: vari } });
        setShowModal(true);
    }
    const GetMarcas = (marcas) => {
        var foo = Marcas;
        const fil = Marcas.filter(x => marcas.some(y => y === x.key))
        return fil;
    }

    //ventana modal
    const VentModal = (content) => {
        switch (content.vent) {
            case eVentModal.AddProd:
                return (<VentPedido param={content.param} setShowModal={setShowModal} />)

            default:
                return (<></>)
        }
    }
    //EVENTOS
    useEffect(() => {
        //  API_Init();
    }, []);

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal}>{VentModal(modalContent)}</Modal>
            <Menu></Menu>
            <Container >
                <br />
                <br />
                <br />
                <Row xs={1} md={2} lg={3} className="g-4">
                    {productos.map((item) => (
                        <Col key={item.id}>
                            <Card className="shadow">
                                <Carousel slide={false}>
                                    {item.images.map((i) => (
                                        <Carousel.Item>
                                            <img key={i}
                                                className="d-block w-100"
                                                src={require(`../img/prods/${i}`)}
                                                alt={i}
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <Card.Body>
                                    <Card.Title>{item.nombre}</Card.Title>
                                    <Card.Text>
                                        <p>{item.descrip}</p>
                                        <div className="h6">
                                            {item.tipo !== undefined ? item.tipo : 'Marcas:'}
                                        </div>
                                        {item.marcas.length > 0 &&
                                            <DropDyn data_key_value={GetMarcas(item.marcas)} id={item.codprod} onchange={onMarcaChange} initValue={item.marsel} />
                                        }
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    {item.variant.map((vari, index) => (
                                        vari.marca === item.marsel &&
                                        <ListGroup.Item
                                            key={index}
                                            action
                                            onClick={() => { onclickprod(item, vari) }}
                                        >
                                                {vari.nombre}&nbsp;&nbsp;&nbsp;&nbsp;<span className="lbsubtitle text-success"><Formater fldtype={eFldType.Currency} text={vari.precio} /> <Button variant="outline-primary">+</Button></span>
                                        </ListGroup.Item>

                                    ))}
                                </ListGroup>
                                <Card.Footer>
                                    <small className="text-muted"> Imagenes con fines ilustrativos</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}

                </Row>
            </Container>
        </>
    )
}

//     ---------------------------  COMPONENTE  ---------------------------------------
const VentPedido = ({ param, setShowModal }) => {
    //param:{prod:{datos del producto},vari:{datos de el variant} }
    const rfCantAddProd = useRef(0);

    //llamadas
    const API_AddProd = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', JSON.stringify(
            {
                cant: rfCantAddProd.current.value,
                producto: param.prod.nombre,
                codprod: param.prod.codprod,
                variant: param.vari.id,
                idmarca: param.vari.marca,
                variname: param.vari.nombre,
                precio: param.vari.precio,
            }));
        const res = await fetch("cproductos/AddProd", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setShowModal(false);

        } else {
            const asd = await res.text();
            alert(asd);
        }

    }

    //funciones
    const onChgCant = (cant) => {

        if (rfCantAddProd.current.value === '1' && cant <= 0) {
            rfCantAddProd.current.value = 1;
            return;
        }
        rfCantAddProd.current.value = parseInt(rfCantAddProd.current.value) + cant;
        //setViewBtnAddProd(parseInt(rfCantAddProd.current.value)>0);

    }
    //EVENTOS
    useEffect(() => {
        rfCantAddProd.current.value = 1;
    }, []);//init
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Agregar a pedido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{param.prod.nombre}</p>
                <p>{param.vari.nombre}</p>
                <Row lg={12}>
                    <Col xs={7} lg={8}></Col>
                    <Col xs={5} lg={4}  >
                        <Form.Text id="passwordHelpBlock" muted>Cantidad</Form.Text>
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="success" onClick={() => { onChgCant(-1) }}>-</Button>
                            <Form.Control type="text" readOnly={true} id="inputPassword5" ref={rfCantAddProd} aria-describedby="passwordHelpBlock" />
                            <Button variant="success" onClick={() => { onChgCant(1) }}>+</Button>
                        </ButtonGroup>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setShowModal(false); }}>Cerrar</Button>
                <Button variant="primary" onClick={() => { API_AddProd(); }}>Agregar</Button>
            </Modal.Footer>
        </>
    )
}
