import React, {  useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import img_Car from "../img/carrito.svg";
import img_Logo from "../img/logoimg.svg";
import { __GetSessionID } from '../controles/Session';
import { useNavigate } from "react-router-dom";



export const Menu = ({ carChange = '' }) => {
    //var ancho = window.innerWidth;
    //var alto = window.innerHeight;
    const navigate = useNavigate();

    const [isAdmin, setisAdmin] = useState(false);
    const [isLogged, setisLogged] = useState(false);
    const [carCount, setCarCount] = useState(0);
    const [change, setChange] = useState('');
    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("chome/GetInfoMenu", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            if (va.login === '1') {
                if (va.isadmin === '1') { setisAdmin(true); } else { setisAdmin(false); }
                setisLogged(true);
            } else {
                setisLogged(false);
                setisAdmin(false);
            }
            setCarCount(va.count);
        }
    }
    const API_Close = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("chome/Close", { method: 'POST', body: data });
        if (res.ok) {
            // const va = await res.json();
            setisLogged(false);
            setisAdmin(false);
            navigate('/login');
        }
    }

    //funciones
    const onClose = () => {
        API_Close();
    }


    API_Init();

    return (
        <Navbar className="bg-nav px-3" expand="lg">

            <Navbar.Brand href="#home" >
                <img className=""
                    alt=""
                    src={img_Logo}
                    width="40"
                    height="40"
                    className="d-inline-block align-top"
                />{' '}
                BIOCOMERCIAL</Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/home">Inicio</Nav.Link>
                    {isLogged &&
                        <>
                            <Nav.Link href="/perfil">Perfil</Nav.Link>
                            <Nav.Link href="/pedidos">Pedidos</Nav.Link>
                        </>
                    }
                </Nav>
                {/*<Form className="d-flex">*/}
                {/*    <Form.Control*/}
                {/*        type="search"*/}
                {/*        placeholder="Buscar"*/}
                {/*        className="me-3"*/}
                {/*        aria-label="Buscar"*/}
                {/*    />*/}
                {/*    <Button className="me-4" variant="outline-success">Buscar</Button>*/}
                {/*</Form>*/}
                <Nav style={{ position: "relative" }}>
                    {isLogged ?
                        <Nav.Link onClick={onClose}>Cerrar Sesion</Nav.Link>
                        :
                        <Nav.Link href="/login">Iniciar Sesion</Nav.Link>
                    }
                    <Nav.Link href="/carrito" style={{ position: "relative" }}><span className="text-success" style={{ position: 'absolute', left: window.innerWidth < 500 ? 12 : 20, top: 5, fontWeight: 600 }}>{carCount}</span> <img height={30} src={img_Car}></img></Nav.Link>

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}