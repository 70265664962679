import { Menu } from "../controles/Menu";
import React, { } from 'react'
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import { eFldType, Formater } from "../controles/Utilidades";
import img_ from "../img/ok.svg";

export const Terminado = () => {
    const { folio, total } = useParams();


    return (
        <>
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <br />
                <Row lg={3} className="centrar">
                    <Col>
                        <Alert className="fw-bold" variant="success">
                            <img variant="top" src={img_} style={{ width: 40 }} />
                            &nbsp;
                            &nbsp;
                            Gracias por su pedido!!
                        </Alert>
                        <Card>

                            <Card.Body>
                                <Card.Title>
                                    Folio:&nbsp;{folio}
                                </Card.Title>
                                <Card.Text>
                                    Total:&nbsp;{<Formater text={total} fldtype={eFldType.Currency} />}
                                </Card.Text>
                                <Card.Text>
                                    Nos pondremos en contacto con usted para confirmar existencias y fecha de envio
                                </Card.Text>
                                <br />
                                <Card.Text>
                                    Gracias por confiar en Bio-Comercial limpieza e Higiene.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </>

    )
}