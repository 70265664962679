import { useEffect, useState, useRef, createContext, useContext } from "react";

import { useNavigate } from "react-router-dom";
import { __GetSessionID } from '../controles/Session';

import { Menu } from "../controles/Menu";
import Button from 'react-bootstrap/Button';
import { Tronar, Alerta, IsValidEmail, DropDown, Formater, eFldType, ResTypePhones, FormatDT } from "../controles/Utilidades.jsx"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';
import NavDropdown from 'react-bootstrap/NavDropdown';
import img_Perfil from "../img/perfil.svg";
import img_Addrs from "../img/address.svg";
import ListGroup from 'react-bootstrap/ListGroup';


//Stack


const MyContext = createContext({});
const useMyContext = () => useContext(MyContext);
const eVentSel = { Principal: 0, Perfil: 1, Direcciones: 2 }
//======================================================================================             H O M E                ================================================================
export const Perfil = () => {
    const [ventSel, setventSel] = useState(eVentSel.Principal);
    const [authorize, setAuthorize] = useState(true);
    const [runComponents, setRunComponents] = useState(false);
    const [sendParams, setSendParams] = useState();
    //llamadas
    const API_Init = async () => {
        const res = await fetch("csocios/Init", { method: 'GET' });
        if (res.ok) {
            //  const va = await res.json();
            setRunComponents(true);
        } else if (res.status === 401) { setAuthorize(false); } else { alert(await res.text()); }
    }
    //context
    const context = {
        setventSel: setventSel,
        setAuthorize: setAuthorize,
        sendParams: sendParams,
        setSendParams: setSendParams
    }

    //funciones

    //EVENTOS
    useEffect(() => {
        //API_Init();
    }, []);//init

    //vent modal
    const PanelMng = (ventSel) => {
        //if (ventSel === undefined || ventSel === '') {
        //    return (<Principal/>);
        //}
        switch (ventSel) {
            case eVentSel.Perfil:
                return (<Info />);

            case eVentSel.Direcciones:
                return (<Direcciones />);

            default:
                return (<Principal />);
        }
    }


    return (
        <MyContext.Provider value={context}>
            {!authorize && <Tronar />}
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <br />
                {PanelMng(ventSel)}
            </Container>
        </MyContext.Provider>

    )
}

//----------------------------------------------------------------------------------- PAGINA PRINCIPAL -----------------------------------------------------
const Principal = () => {
    let { setventSel } = useMyContext();


    return (
        <Row xs={1} md={2} lg={3} className="g-4">
            <Col >
                <Card border="success" className="pointer text-blue" onClick={() => { setventSel(eVentSel.Perfil) }}>
                    <Card.Title><img variant="top" src={img_Perfil} style={{ width: 50 }} />&nbsp;Tu Perfil</Card.Title>
                </Card>
            </Col>
            <Col >
                <Card border="success" className="pointer text-blue" onClick={() => { setventSel(eVentSel.Direcciones) }}>
                    <Card.Title><img variant="top" src={img_Addrs} style={{ width: 50 }} />&nbsp;Direcciones de entrega</Card.Title>
                </Card>
            </Col>
        </Row>

    )
}
//----------------------------------------------------------------------------------- PERFIL -----------------------------------------------------

const Info = () => {
    const { setAuthorize } = useMyContext();

    const rfPwd = useRef('');
    const rfPwd2 = useRef('');
    const rfCorreo = useRef('');
    const rfUser = useRef('');
    const rfNames = useRef('');
    const rfLastNames = useRef('');
    const rfPhone = useRef('');
    const rfTPhone = useRef('');

    const [show, setShow] = useState(false);
    const [defaultVals, setDefaultVals] = useState({ username: '', mail: '', phones: [] });
    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("cuser/InitPerfil", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            rfCorreo.current.value = va.mail;
            rfUser.current.value = va.username;
            rfNames.current.value = va.nombres;
            rfLastNames.current.value = va.apellidos;
            setDefaultVals({ ...defaultVals, username: va.username, mail: va.mail, phones: va.phones });

        } else if (res.status === 401) { setAuthorize(false); } else { alert(await res.text()); }
    }
    const API_SaveUser = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', JSON.stringify({
            mail: rfCorreo.current.value,
            nombres: rfNames.current.value,
            apellidos: rfLastNames.current.value,
            user: rfUser.current.value
        }));
        const res = await fetch("cuser/SaveUser", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            if (va.isvalid !== 'true') {
                Alerta("Ya existe un usuario con esta informaci&oacute;n ");
                rfCorreo.current.value = defaultVals.mail;
                rfUser.current.value = defaultVals.username;
            }
            else {
                Alerta("Guardado! <br/> Vuelva a iniciar sesion par aplicar los cambios");
                setDefaultVals({ ...defaultVals, username: rfUser.current.value, mail: rfCorreo.current.value });

            }
        } else if (res.status === 401) { setAuthorize(false); } else { Alerta(await res.text()); }
    }
    const API_SavePwd = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', JSON.stringify({
            pwd: rfPwd.current.value
        }));
        const res = await fetch("cuser/SavePwd", { method: 'POST', body: data });
        if (res.ok) {
            Alerta("Guardado!");
        } else if (res.status === 401) { setAuthorize(false); } else { Alerta(await res.text()); }
    }
    const API_SavePhone = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', JSON.stringify({
            phone: rfPhone.current.value,
            typephone: rfTPhone.current.value
        }));
        const res = await fetch("cuser/SavePhone", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setDefaultVals({ ...defaultVals, phones: va.phones });
            rfPhone.current.value = '';
        } else if (res.status === 401) { setAuthorize(false); } else { Alerta(await res.text()); }
    }
    const API_DelPhone = async (value) => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        data.append('value', value);
        const res = await fetch("cuser/DelPhone", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setDefaultVals({ ...defaultVals, phones: va.phones });
        } else if (res.status === 401) { setAuthorize(false); } else { Alerta(await res.text()); }
    }

    //funciones
    const onSaveUserData = () => {
        if (rfCorreo.current.value === '') { Alerta('Escriba un correo electr&oacute;nico'); return; }
        if (!IsValidEmail(rfCorreo.current.value)) { Alerta('Escriba un correo valido'); return; }
        if (rfUser.current.value === '') { Alerta('Escriba un nombre de usuario'); return; }
        API_SaveUser();

    }
    const onChgPwd = () => {
        if (rfPwd.current.value === '') { Alerta('Escriba un password'); return; }
        if (rfPwd2.current.value === '') { Alerta('Confirme el password'); return; }
        if (rfPwd.current.value !== rfPwd2.current.value) { Alerta('Password no coinciden'); return; }
        if (rfPwd.current.value.length < 8) { Alerta('Password Requiere 8 caracteres m&iacute;nimo'); return; }
        API_SavePwd();
        setShow(false);
    }
    const onSavePhone = () => {
        if (rfTPhone.current.value === '' || rfTPhone.current.value === '0') { Alerta('Seleccione un tipo de telefono'); return; }
        if (rfPhone.current.value.trim().length < 10) { Alerta('Escriba un numero de telefono valido'); return; }
        API_SavePhone();
    }
    const onDelPhone = (value) => {
        if (value === undefined || value === '') { Alerta('Seleccione un telefono'); return; }
        API_DelPhone(value);
    }

    //EVENTOS
    useEffect(() => {
        API_Init();
    }, []);//init

    return (
        <Row lg={4} className="centrar">
            <Modal show={show} onHide={() => { setShow(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar contrase&ntilde;a</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="Form.pwd">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="8 caracteres m&iacute;nimo " ref={rfPwd} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="Form.confirm">
                            <Form.Label>Confirmar Password</Form.Label>
                            <Form.Control type="password" placeholder="volver a escribir password" ref={rfPwd2} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShow(false) }} >
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={onChgPwd} >
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/*usuario*/}
            <Col>
                <Card border="primary">
                    <Card.Body>
                        <Card.Title className="text-blue" >Informaci&oacute;n de Sesi&oacute;n </Card.Title>
                        <Form>
                            <Form.Group className="mb-3" controlId="Form.User">
                                <Form.Label>Nombre de usuario</Form.Label>
                                <Form.Control type="text" placeholder="30 m&aacute;ximo letras y/o numeros" ref={rfUser} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Form.mail">
                                <Form.Label>Correo electr&oacute;nico</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" ref={rfCorreo} />
                            </Form.Group>
                            <Card.Title className="text-blue" >Informaci&oacute;n personal </Card.Title>
                            <Form>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Nombre's</Form.Label>
                                    <Form.Control type="text" ref={rfNames} />
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Apellidos</Form.Label>
                                    <Form.Control type="email" ref={rfLastNames} />
                                </Form.Group>
                            </Form>

                            <Form.Group className="mb-3" >
                                <Button variant="success" onClick={() => { onSaveUserData(); }}>Guardar</Button>
                            </Form.Group>
                            <Form.Group className="mb-3" >

                                <Button variant="primary" onClick={() => { setShow(true); }}>Cambiar contrase&ntilde;a</Button>
                            </Form.Group>
                        </Form>

                    </Card.Body>
                </Card>
            </Col>
            
            {/*telefonos*/}
            <Col>
                <Card border="primary">
                    <Card.Body>
                        <Card.Title className="text-blue" >Telefonos</Card.Title>
                        <small className="text-muted">Nos comunicar&eacute;mos a estos numeros si existe alg&uacute;n problema con su pedido</small>
                        <Form>
                            <Form.Group className="mb-3" >
                                <Form.Label>Nuevo</Form.Label>
                                <Form.Control type="text" placeholder="10 digitos" ref={rfPhone} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <DropDown drpRef={rfTPhone} data_key_value={ResTypePhones} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Button variant="success" onClick={() => { onSavePhone(); }}>Agregar</Button>
                            </Form.Group>
                        </Form>
                        {defaultVals.phones.length > 0 &&
                            <ListGroup>
                                {
                                    defaultVals.phones.map((item) => (
                                        <ListGroup.Item
                                            key={item.uid}
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold"><span className="text-muted"><Formater fldtype={eFldType.Resource} text={item.typephone} resourceKV={ResTypePhones} /> </span> </div>
                                                <div className="fw-bold">
                                                    {item.phone}
                                                </div>
                                            </div>
                                            <div>
                                                <Badge onClick={() => { onDelPhone(item.uid) }} className="pointer m-2" bg="danger" pill>X</Badge>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                            </ListGroup>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

//----------------------------------------------------------------------------------- DIRECCIONES -----------------------------------------------------

export const Direcciones = () => {
    let { setAuthorize } = useMyContext();
    const navigate = useNavigate();
    const [direcciones, setDirecciones] = useState([]);

    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("cuser/Direcciones", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setDirecciones(va.direcciones);
        } else if (res.status === 401) { setAuthorize(false); } else { alert(await res.text()); }
    }

    //funciones 
    const onNewDir = () => {
        navigate('/newdir');
    }
    const onEdit = (uid) => {
        navigate(`/newdir/${uid}`);
    }
    //EVENTOS
    useEffect(() => {
        API_Init();
    }, []);//init

    return (
        <Row lg={2} className="centrar">
            <Card border="primary">
                <Card.Header>
                    <Button variant="primary" onClick={() => { onNewDir() }}>Agregar Nueva</Button>
                </Card.Header>
                <Card.Body>
                    <Card.Title className="text-blue" >Direcciones de entrega</Card.Title>
                    <ListGroup as="ul" >
                        {direcciones.map((item) => (
                            <ListGroup.Item
                                key={item.uid}
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="flex">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{item.alias}</div>
                                        <div>
                                            {item.calle}&nbsp;{item.numero}&nbsp;{item.numint}
                                        </div>
                                        {item.colonia.length > 0 &&
                                            <div>
                                                Colonia&nbsp;{item.colonia}
                                            </div>
                                        }
                                        {item.cpostal.length > 0 &&
                                            <div>
                                                CP&nbsp;{item.cpostal}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.ciudad}&nbsp;{item.estado}
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div>
                                    <Badge onClick={() => { onEdit(item.uid); }} className="pointer m-2" bg="success" pill>Editar</Badge>
                                </div>
                            </ListGroup.Item>
                        ))
                        }


                    </ListGroup>
                </Card.Body>
            </Card>
        </Row>
    )
}

