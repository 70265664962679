import { Menu } from "../controles/Menu";
import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { eFldType, Formater } from "../controles/Utilidades";

import { __GetSessionID } from '../controles/Session';

export const Pedidos = () => {
    const [pedidosH, setPedidosH] = useState([]);
    const [pedidosD, setPedidosD] = useState([]);

    //llamadas
    const API_Init = async () => {
        const data = new FormData();
        data.append('sessid', __GetSessionID());
        const res = await fetch("cpedidos/GetPedidos", { method: 'POST', body: data });
        if (res.ok) {
            const va = await res.json();
            setPedidosH(va.pedidosh);
            setPedidosD(va.pedidosd);
        } else {
            const asd = await res.text();
            alert(asd);
        }

    }



    //EVENTOS
    useEffect(() => {
        API_Init();
    }, []);
    return (
        <>
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <br />
                <Row xs={1} md={2} lg={1} className=" centrar">
                    <Card>
                        <br />
                        <Card.Title >Historial de pedidos</Card.Title>
                        <Card.Text>Ultimos 50 pedidos</Card.Text>
                        <Card.Body>
                            <Accordion >
                                {pedidosH.map((h, index) => (

                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header  >
                                            <div className="col-lg-10 col-xs-12 flex flexAE">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Folio</div>
                                                    {h.uid}
                                                </div>

                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Cliente</div>
                                                    {h.nombre}
                                                </div>

                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Telefono</div>
                                                    {h.telefono}
                                                </div>

                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Fecha</div>
                                                    {<Formater text={h.fecha} fldtype={eFldType.DateTime} />}
                                                </div>

                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Total</div>
                                                    {<Formater text={h.total} fldtype={eFldType.Currency} />}

                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        {pedidosD.filter(x => x.uidparent === h.uid).map((item) => (
                                            <Accordion.Body>
                                                <div key={item.uid} className="col-lg-10 col-xs-12 flex  w100" style={{ overflow: 'auto' }}>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">Cant</div>
                                                        {item.cant}
                                                    </div>

                                                    <div className="ms-2 me-auto" style={{ minWidth: 240 }}>
                                                        <div className="fw-bold">Producto</div>
                                                        <Card.Text >
                                                            {item.nombre}
                                                        </Card.Text>
                                                        &nbsp;&nbsp;{item.marca}&nbsp;
                                                        {item.varname}
                                                    </div>

                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">Precio</div>
                                                        {<Formater text={item.precio} fldtype={eFldType.Currency} />}
                                                    </div>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">Desc</div>
                                                        {<Formater text={item.descuento} fldtype={eFldType.Currency} />}
                                                    </div>
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">Importe</div>
                                                        {<Formater text={item.importe} fldtype={eFldType.Currency} />}
                                                    </div>
                                                </div>
                                            </Accordion.Body>

                                        ))
                                        }
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Card.Body>
                    </Card>
                </Row>
            </Container >

        </>
    )
}