import { Menu } from "../controles/Menu";
import React, { } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import Stack from 'react-bootstrap/Stack';


import img_Hig from "../img/grp_hig.png";
import img_No from "../img/noimage.png";
import img_pyd from "../img/pasticos_desechables.png";
import img_dyj from "../img/detergentes.jpg";
import img_lya from "../img/limpyarom.jpg";
import img_cyc from "../img/cesycon.jpg";
import img_aba from "../img/abarrotes.jpg";
import img_des from "../img/despachadores.jpg";
import img_jar from "../img/Jarcieria.jpg";
export const Home = () => {


    //useEffect(() => { API_Init(); }, [])limpyarom

    return (
        <>
            <Menu ></Menu>
            <Container >
                <br />
                <br />
                <br />
                <Row xs={1} md={2} lg={3} className="g-4">
                    <Col >
                        <a href="/productos/1/0/*">
                            <Card className="shadow">
                                <Card.Img variant="top" src={img_Hig} />
                                <Card.Body>
                                    <Card.Title>Higienicos y toallas</Card.Title>
                                    <div className="flex flexAr">
                                        <Button > Ver Mas</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col >
                        <a href="/productos/2/0/*">
                            <Card className="shadow">
                                <Card.Img variant="top" src={img_pyd} />
                                <Card.Body>
                                    <Card.Title>Plasticos y Desechables</Card.Title>
                                    <div className="flex flexAr">
                                        <Button > Ver Mas</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col >
                        <a href="/productos/4/0/*">
                            <Card className="shadow">
                                <Card.Img variant="top" src={img_lya} />
                                <Card.Body>
                                    <Card.Title>Limpiadores y Aromatizantes</Card.Title>
                                    <div className="flex flexAr">
                                        <Button > Ver Mas</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col >
                        <a href="/productos/3/0/*">
                            <Card className="shadow">
                                <Card.Img variant="top" src={img_dyj} />
                                <Card.Body>
                                    <Card.Title>Detergentes y Jabones</Card.Title>
                                    <div className="flex flexAr">
                                        <Button > Ver Mas</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                    <Col >
                        <a href="/productos/7/0/*">
                            <Card className="shadow">
                                <Card.Img variant="top" src={img_des} />
                                <Card.Body>
                                    <Card.Title>Despachadores, Art para ba&ntilde;o</Card.Title>
                                    <div className="flex flexAr">
                                        <Button > Ver Mas</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

