
export const DBProductos = [
    {
        id: 0,
        codprod: 'P0001',
        nombre: 'Papel Higiénico Jr.',
        descrip: 'En las siguientes marcas:',
        marcas: ['1'],
        marsel:'1',
        images: ['P0001.jpg'],
        familia:'1',
        variant: [
            { id: '01', marca:'1', nombre: 'Caja Con 12 rollos 160mts',precio:349 },
            { id: '02', marca: '1', nombre: 'Caja Con 12 rollos 180mts', precio: 399 },
            { id: '03', marca: '1', nombre: 'Caja Con 12 rollos 250mts', precio: 569 },
        ]
    },
    {
        id: 1,
        codprod: 'P0002',
        nombre: 'Papel Higiénico Jm.',
        descrip: 'En las siguientes marcas:',
        marcas: ['1', '3',],
        marsel:'1',
        images: [ 'P0002-1.jpg','P0002-2.png'],
        familia: '1',
        variant: [
            { id: '01', marca: '1', nombre: 'Caja Con 6 rollos 360mts', precio: 399 },
            { id: '02', marca: '3', nombre: 'Caja Con 6 rollos 250mts', precio: 349 },
        ]
    },
    {
        id: 2,
        codprod: 'P0003',
        nombre: 'Higiénicos tradicionales.',
        descrip: 'En las siguientes marcas:',
        marcas: ['3'],
        marsel:'3',
        images: ['P0003.png'],
        familia: '1',
        variant: [
            { id: '01', marca: '3', nombre: 'Paquete Con 40 rollos ', precio: 229 },
            
        ]
    },
    {
        id: 3,
        codprod: 'P0004',
        nombre: 'Toallas interdobladas',
        descrip: 'En las siguientes marcas:',
        marcas: ['19', '8'],
        marsel: '19',
        images: ['P0004.jpg', 'P0004-1.jpg', 'P0004-2.jpg'],
        familia: '1',
        variant: [
            { id: '01', marca: '19', nombre: 'Caja 20 paq. de 100 toallas blancas ', precio: 299 },
            { id: '02', marca: '8', nombre: 'Caja 20 paq. de 100 toallas blancas ', precio: 275 },
            { id: '03', marca: '8', nombre: 'Paquete de 2000 toallas cafe ', precio: 239 },
            { id: '04', marca: '8', nombre: 'Paquete de 4000 toallas blancas', precio: 569 },
        ]
    },
    {
        id: 4,
        codprod: 'P0005',
        nombre: 'Toalla en rollo blanca y café ',
        descrip: 'En las siguientes marcas:',
        marcas: [ '8', '4'],
        marsel: '8',
        images: [ 'P0005-1.png', 'P0005-2.jpg', 'P0005-3.png'],
        familia: '1',
        variant: [
            { id: '01', marca: '8', nombre: 'Caja 6 Rollos 160m blanca ', precio: 499 },
            { id: '02', marca: '8', nombre: 'Caja 6 Rollos 160m cafe ', precio: 349 },
            { id: '03', marca: '4', nombre: 'Caja 6 Rollos 180m cafe ', precio: 399 },
        ]
    },
    {
        id: 5,
        codprod: 'P0007',
        nombre: 'Pañuelos faciales Kleneex',
        descrip: 'En las siguientes marcas:',
        marcas: ['18'],
        marsel: '18',
        images: ['P0007.jpg'],
        familia: '1',
        variant: [
            { id: '01', marca: '18', nombre: 'Caja con 90 pzas ', precio: 24 },

        ]
    },
    {
        id: 6,
        codprod: 'P0006',
        nombre: 'Servilletas tradicional desechable, Barra mesa continua',
        descrip: 'En las siguientes marcas:',
        marcas: ['3', '11', '14'],
        marsel: '3',
        images: ['P0006.jpg', 'P0006-2.jpg','P0006-6.jpg'],
        familia: '1',
        variant: [
            { id: '01', marca: '14', nombre: 'Caja con 24 paq. de 220 hojas', precio: 519 },
            { id: '02', marca: '11', nombre: 'Caja con 12 paq. de 450 hojas', precio: 499 },
            { id: '03', marca: '11', nombre: 'Paquete de 450 hojas', precio: 43 },
            { id: '04', marca: '3', nombre: 'Caja con 12 paq. de 450 hojas', precio: 649 },
            { id: '05', marca: '3', nombre: 'Paquete de 450 hojas', precio: 58 },
            { id: '06', marca: '3', nombre: 'Barra mesa continua Caja 24 paq.de 250pzas', precio: 519 },

        ]
    },

    {
        id: 27,
        codprod: 'P2001',
        nombre: 'Cubiertos desechables',
        descrip: '',
        marcas: ['2011'],
        marsel: '2011',
        images: ['P2001.jpg', 'P2001-1.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '2011', nombre: 'Cuchara chica paq c/50 pzas', precio: 9 },
            { id: '02', marca: '2011', nombre: 'Tenedor chico paq c/50 pzas', precio: 9 },
            { id: '03', marca: '2011', nombre: 'Cuchillo chico paq c/25 pzas', precio: 10 },
        ]
    },
    {
        id: 28,
        codprod: 'P2002',
        descrip: 'En las siguientes marcas:',
        nombre: 'Cubiertos desechables',
        marcas: ['26'],
        marsel: '26',
        images: ['P2002.jpg', 'P2002-1.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '26', nombre: 'Vaso termico 8oz paq c/25 pzas', precio: 16 },
        ]
    },
    {
        id: 29,
        codprod: 'P2003',
        descrip: 'En las siguientes marcas:',
        nombre: 'Vaso transparente desechables',
        marcas: ['27'],
        marsel: '27',
        images: ['P2003.jpg', 'P2003-1.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '27', nombre: 'Vaso  8oz paq c/50 pzas', precio: 35 },
            { id: '02', marca: '27', nombre: 'Vaso 10oz paq c/50 pzas', precio: 40 },
            { id: '03', marca: '27', nombre: 'Vaso 12oz paq c/50 pzas', precio: 43.50 },
            { id: '04', marca: '27', nombre: 'Vaso 14oz paq c/50 pzas', precio: 45 },
        ]
    },
    {
        id: 30,
        codprod: 'P2004',
        descrip: 'En las siguientes marcas:',
        nombre: 'Vaso cono de papel #106',
        marcas: ['28'],
        marsel: '28',
        images: ['P2004.jpg', 'P2004-1.jpg', 'P2004-2.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '28', nombre: 'Paquete c/250 pzas', precio: 65 },
            { id: '02', marca: '28', nombre: 'Caja 20 paq. 5000 pzas', precio: 1199 },
        ]
    },
    {
        id: 31,
        codprod: 'P2005',
        descrip: 'En los siguientes colores:',
        nombre: 'Bolsa de plastico x kilo',
        marcas: ['2051', '2052', '2053','2054'],
        marsel: '2051',
        tipo:'Colores',
        images: ['P2005.jpg', 'P2005-1.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '2051', nombre: 'Jumbo 70x30x120 x kilo', precio: 36 },
            { id: '02', marca: '2051', nombre: 'Mediana 70x90 x kilo', precio: 36 },
            { id: '03', marca: '2051', nombre: 'Chica 70x50 x kilo', precio: 36 },
            { id: '04', marca: '2052', nombre: 'Jumbo 70x30x120 x kilo', precio: 50 },
            { id: '05', marca: '2052', nombre: 'Mediana 70x90 x kilo', precio: 50 },
            { id: '06', marca: '2052', nombre: 'Chica 70x50 x kilo', precio: 50 },
            { id: '07', marca: '2053', nombre: 'Jumbo 70x30x120 x kilo', precio: 50 },
            { id: '08', marca: '2053', nombre: 'Mediana 70x90 x kilo', precio: 50 },
            { id: '09', marca: '2053', nombre: 'Chica 70x50 x kilo', precio: 50 },
            { id: '10', marca: '2054', nombre: 'Jumbo 70x30x120 x kilo', precio: 50 },
            { id: '11', marca: '2054', nombre: 'Mediana 70x90 x kilo', precio: 50 },
            { id: '12', marca: '2054', nombre: 'Chica 70x50 x kilo', precio: 50 },
        ]
    },
    {
        id: 32,
        codprod: 'P2006',
        descrip: 'Los rollos pueden variar en peso',
        nombre: 'Rollo bolsa de plastico transparente x kilo',
        marcas: ['2061','2062'],
        marsel: '2061',
        tipo: 'Densidad',
        images: ['P2006.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '2061', nombre: 'Rollo 15x25 precio x kilo', precio: 63.50 },
            { id: '02', marca: '2061', nombre: 'Rollo 25x35 precio x kilo', precio: 63.50 },
            { id: '03', marca: '2061', nombre: 'Rollo 35x45 precio x kilo', precio: 63.50 },
            { id: '04', marca: '2061', nombre: 'Rollo 40x60 precio x kilo', precio: 63.50 },
            { id: '05', marca: '2061', nombre: 'Rollo 50x70 precio x kilo', precio: 63.50 },
            { id: '06', marca: '2061', nombre: 'Rollo 60x90 precio x kilo', precio: 63.50 },
            { id: '07', marca: '2062', nombre: 'Rollo 15x25 precio x kilo', precio: 63.50 },
            { id: '08', marca: '2062', nombre: 'Rollo 25x35 precio x kilo', precio: 63.50 },
            { id: '09', marca: '2062', nombre: 'Rollo 35x45 precio x kilo', precio: 63.50 },
            { id: '10', marca: '2062', nombre: 'Rollo 40x60 precio x kilo', precio: 63.50 },
            { id: '11', marca: '2062', nombre: 'Rollo 50x70 precio x kilo', precio: 63.50 },
            { id: '12', marca: '2062', nombre: 'Rollo 60x90 precio x kilo', precio: 63.50 },
        ]
    },
    {
        id: 33,
        codprod: 'P2007',
        descrip: 'En los siguientes colores:',
        nombre: 'Bolsa camiseta x kilo',
        marcas: ['2051'],
        marsel: '2051',
        tipo: 'Colores',
        images: ['P2007.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '2051', nombre: 'Jumbo x kilo', precio: 45 },
            { id: '02', marca: '2051', nombre: 'Grande x kilo', precio: 45 },
            { id: '03', marca: '2051', nombre: 'Mediana x kilo', precio: 45 },
            { id: '04', marca: '2051', nombre: 'Chica x kilo', precio: 45 },

        ]
    },
    {
        id: 34,
        codprod: 'P2008',
        descrip: 'En las siguientes marcas:',
        nombre: 'Charola plato desechable',
        marcas: ['27'],
        marsel: '27',
        images: ['P2008.jpg', 'P2008-1.jpg'],
        familia: '2',
        variant: [
            { id: '01', marca: '27', nombre: 'Cuadrada paq. c/50 pzas', precio: 30 },
            { id: '02', marca: '27', nombre: 'Rectangular paq. c/50 pzas', precio: 39 },

        ]
    },

    {
        id: 8,
        codprod: 'P3001',
        nombre: 'Jabon Liquido para Trastes Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['3011'],
        marsel: '3011',
        tipo: '',
        images: ['biocomercial.jpg'],
        familia: '3',
        variant: [
            { id: '01', marca: '3011', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '02', marca: '3011', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '03', marca: '3011', nombre: 'Envase 1 litro ', precio: 17.5 },
            
        ]
    },
    {
        id: 12,
        codprod: 'P4006',
        nombre: 'Jabon Liquido para Manos Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4061', '4062', '4063', '4064', '4065',],
        marsel: '4061',
        tipo: 'Aromas:',
        images: ['biocomercial.jpg'],
        familia: '3',
        variant: [
            { id: '01', marca: '4061', nombre: 'Envase de 20 litros ', precio: 325 },
            { id: '02', marca: '4061', nombre: 'Envase de 5 litros', precio: 96 },
            { id: '03', marca: '4061', nombre: 'Envase 1 litro ', precio: 19.25 },
            { id: '04', marca: '4062', nombre: 'Envase de 20 litros ', precio: 325 },
            { id: '05', marca: '4062', nombre: 'Envase de 5 litros', precio: 96 },
            { id: '06', marca: '4062', nombre: 'Envase 1 litro ', precio: 19.25 },
            { id: '07', marca: '4063', nombre: 'Envase de 20 litros ', precio: 325 },
            { id: '08', marca: '4063', nombre: 'Envase de 5 litros', precio: 96 },
            { id: '09', marca: '4063', nombre: 'Envase 1 litro ', precio: 19.25 },
            { id: '10', marca: '4064', nombre: 'Envase de 20 litros ', precio: 325 },
            { id: '11', marca: '4064', nombre: 'Envase de 5 litros', precio: 96 },
            { id: '12', marca: '4064', nombre: 'Envase 1 litro ', precio: 19.25 },
            { id: '13', marca: '4065', nombre: 'Envase de 20 litros ', precio: 325 },
            { id: '14', marca: '4065', nombre: 'Envase de 5 litros', precio: 96 },
            { id: '15', marca: '4065', nombre: 'Envase 1 litro ', precio: 19.25 },
            
        ]
    },
    {
        id: 13,
        codprod: 'P4007',
        nombre: 'Jabon Liquido para Ropa Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4071', '4072', '4073', '4074', '4075', '4076', '4077', '4078', '4079'],
        marsel: '4071',
        tipo: 'Aromas:',
        images: ['biocomercial.jpg'],
        familia: '3',
        variant: [
            { id: '01', marca: '4071', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '02', marca: '4071', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '03', marca: '4071', nombre: 'Envase 1 litro ', precio: 17.5},
            { id: '04', marca: '4072', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '05', marca: '4072', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '06', marca: '4072', nombre: 'Envase 1 litro ', precio: 17.5 },
            { id: '07', marca: '4073', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '08', marca: '4073', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '09', marca: '4073', nombre: 'Envase 1 litro ', precio: 17.5 },
            { id: '10', marca: '4074', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '11', marca: '4074', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '12', marca: '4074', nombre: 'Envase 1 litro ', precio: 17.5 },
            { id: '13', marca: '4075', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '14', marca: '4075', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '15', marca: '4075', nombre: 'Envase 1 litro ', precio: 17.5 },
            { id: '16', marca: '4076', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '17', marca: '4076', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '18', marca: '4076', nombre: 'Envase 1 litro ', precio: 17.5 },
            { id: '19', marca: '4077', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '20', marca: '4077', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '21', marca: '4077', nombre: 'Envase 1 litro ', precio: 17.5 },
            { id: '22', marca: '4078', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '23', marca: '4078', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '24', marca: '4078', nombre: 'Envase 1 litro ', precio: 17.5 },
            { id: '25', marca: '4079', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '26', marca: '4079', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '27', marca: '4079', nombre: 'Envase 1 litro ', precio: 17.5 },

        ]
    },
    {
        id: 22,
        codprod: 'P3002',
        nombre: 'Jabon en Polvo Bio-Comercial',
        descrip: '',
        marcas: ['3021','4076','4075'],
        marsel: '3021',
        tipo: 'Tipo',
        images: ['P3002.jpg'],
        familia: '3',
        variant: [
            { id: '01', marca: '3021', nombre: 'Bolsa de 10 Kg', precio: 189 },
            { id: '02', marca: '4075', nombre: 'Bolsa de 10 Kg', precio: 189 },
            { id: '03', marca: '4076', nombre: 'Bolsa de 10 Kg', precio: 189 },
        ]
    },

    {
        id: 7,
        codprod: 'P4013',
        nombre: 'Aromatizante Ambiental Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4131',  '4133', '4134'],
        marsel: '4131',
        tipo: 'Aromas:',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4131', nombre: 'Envase de 20 litros ', precio: 825 },
            { id: '02', marca: '4131', nombre: 'Envase de 5 litros', precio: 233.5 },
            { id: '03', marca: '4131', nombre: 'Envase 1 litro ', precio: 45.5 },
            { id: '07', marca: '4133', nombre: 'Envase de 20 litros ', precio: 825 },
            { id: '08', marca: '4133', nombre: 'Envase de 5 litros', precio: 233.5 },
            { id: '09', marca: '4133', nombre: 'Envase 1 litro ', precio: 45.5 },
            { id: '10', marca: '4134', nombre: 'Envase de 20 litros ', precio: 825 },
            { id: '11', marca: '4134', nombre: 'Envase de 5 litros', precio: 233.5 },
            { id: '12', marca: '4134', nombre: 'Envase 1 litro ', precio: 45.5 },
        ]
    },
    {
        id: 9,
        codprod: 'P4014',
        nombre: 'Desengrasante Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4141', '4142'],
        marsel: '4141',
        tipo: 'Concentraci\u00F3n:',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4141', nombre: 'Envase de 20 litros ', precio: 265 },
            { id: '02', marca: '4141', nombre: 'Envase de 5 litros', precio: 79.5 },
            { id: '03', marca: '4141', nombre: 'Envase 1 litro ', precio: 16.1 },
            { id: '04', marca: '4142', nombre: 'Envase de 20 litros ', precio: 365 },
            { id: '05', marca: '4142', nombre: 'Envase de 5 litros', precio: 107 },
            { id: '06', marca: '4142', nombre: 'Envase 1 litro ', precio: 21.35 },
        ]
    },
    {
        id: 10,
        codprod: 'P4004',
        nombre: 'Cloro Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4041', '4042'],
        marsel: '4041',
        tipo:'Concentracion:',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4041', nombre: 'Envase de 20 litros ', precio: 125 },
            { id: '02', marca: '4041', nombre: 'Envase de 5 litros', precio: 31 },
            { id: '03', marca: '4041', nombre: 'Envase 1 litro ', precio: 8.75 },
            { id: '04', marca: '4042', nombre: 'Envase de 20 litros ', precio: 285 },
            { id: '05', marca: '4042', nombre: 'Envase de 5 litros', precio: 85 },
            { id: '06', marca: '4042', nombre: 'Envase 1 litro ', precio: 17.15 },
        ]
    },
    {
        id: 11,
        codprod: 'P4005',
        nombre: 'Fabuloso Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4051', '4052', '4053', '4054', '4055', '4056', '4057', '4058', '4059','40510'],
        marsel: '4051',
        tipo:'Aromas:',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4051', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '02', marca: '4051', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '03', marca: '4051', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '04', marca: '4052', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '05', marca: '4052', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '06', marca: '4052', nombre: 'Envase 1 litro ', precio: 9.8 }, 
            { id: '07', marca: '4053', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '08', marca: '4053', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '09', marca: '4053', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '10', marca: '4054', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '11', marca: '4054', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '12', marca: '4054', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '13', marca: '4055', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '14', marca: '4055', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '15', marca: '4055', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '16', marca: '4056', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '17', marca: '4056', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '18', marca: '4056', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '19', marca: '4057', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '20', marca: '4057', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '21', marca: '4057', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '22', marca: '4058', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '23', marca: '4058', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '24', marca: '4058', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '25', marca: '4059', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '26', marca: '4059', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '27', marca: '4059', nombre: 'Envase 1 litro ', precio: 9.8 },
            { id: '28', marca: '40510', nombre: 'Envase de 20 litros ', precio: 145 },
            { id: '29', marca: '40510', nombre: 'Envase de 5 litros', precio: 46.40 },
            { id: '30', marca: '40510', nombre: 'Envase 1 litro ', precio: 9.8 },
        ]
    },
    {
        id: 15,
        codprod: 'P4009',
        nombre: 'Quita Sarro Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4091', '4092'],
        marsel: '4091',
        tipo: 'Presentaci\u00F3n:',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4091', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '02', marca: '4091', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '03', marca: '4091', nombre: 'Envase 1 litro ', precio: 14 },
            { id: '04', marca: '4092', nombre: 'Envase de 20 litros ', precio: 365 },
            { id: '05', marca: '4092', nombre: 'Envase de 5 litros', precio: 107 },
            { id: '06', marca: '4092', nombre: 'Envase 1 litro ', precio: 21.35 },

        ]
    },
    {
        id: 14,
        codprod: 'P4008',
        nombre: 'Suavizante de telas Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4081', '4082', '4083', '4084', '4085', '4086'],
        marsel: '4081',
        tipo: 'Aromas:',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4081', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '02', marca: '4081', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '03', marca: '4081', nombre: 'Envase 1 litro ', precio: 14 },
            { id: '04', marca: '4082', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '05', marca: '4082', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '06', marca: '4082', nombre: 'Envase 1 litro ', precio: 14 },
            { id: '07', marca: '4083', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '08', marca: '4083', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '09', marca: '4083', nombre: 'Envase 1 litro ', precio: 14 },
            { id: '10', marca: '4084', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '11', marca: '4084', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '12', marca: '4084', nombre: 'Envase 1 litro ', precio: 14 },
            { id: '13', marca: '4085', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '14', marca: '4085', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '15', marca: '4085', nombre: 'Envase 1 litro ', precio: 14 },
            { id: '16', marca: '4086', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '17', marca: '4086', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '18', marca: '4086', nombre: 'Envase 1 litro ', precio: 14 },


        ]
    },
    {
        id: 16,
        codprod: 'P4010',
        nombre: 'Limpia Vidrios Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4101'],
        marsel: '4101',
        tipo: '',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4101', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '02', marca: '4101', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '03', marca: '4101', nombre: 'Envase 1 litro ', precio: 14 },
        ]
    },
    {
        id: 17,
        codprod: 'P4011',
        nombre: 'Gel Desinfectante para Manos  Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4111'],
        marsel: '4111',
        tipo: '',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4111', nombre: 'Envase de 20 litros ', precio: 825 },
            { id: '02', marca: '4111', nombre: 'Envase de 5 litros', precio: 233.5 },
            { id: '03', marca: '4111', nombre: 'Envase 1 litro ', precio: 45.5 },
        ]
    },
    {
        id: 18,
        codprod: 'P4012',
        nombre: 'Pinol Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4112'],
        marsel: '4112',
        tipo: '',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4112', nombre: 'Envase de 20 litros ', precio: 225 },
            { id: '02', marca: '4112', nombre: 'Envase de 5 litros', precio: 68.5 },
            { id: '03', marca: '4112', nombre: 'Envase 1 litro ', precio: 14.5 },
        ]
    },
    {
        id: 19,
        codprod: 'P4001',
        nombre: 'Cloralex',
        descrip: 'En las siguientes marcas:',
        marcas: ['23'],
        marsel: '23',
        images: ['P4001.jpg',],
        familia: '4',
        variant: [
            { id: '01', marca: '23', nombre: 'Envase 950ml ', precio: 18.50 },

        ]
    },
    {
        id: 20,
        codprod: 'P4003',
        nombre: 'Fabuloso',
        descrip: 'En las siguientes marcas:',
        marcas: ['24'],
        marsel: '24',
        images: ['P4003.jpg', 'P4003-1.jpg', 'P4003-2.jpg',],
        familia: '4',
        variant: [
            { id: '01', marca: '24', nombre: 'Lavanda 1 litro ', precio: 33.50 },
            { id: '02', marca: '24', nombre: 'Mar Fresco 1 litro ', precio: 33.50 },
            { id: '03', marca: '24', nombre: 'Frutas 1 litro ', precio: 33.50 },
        ]
    },
    {
        id: 21,
        codprod: 'P4002',
        nombre: 'Pinol',
        descrip: 'En las siguientes marcas:',
        marcas: ['23'],
        marsel: '23',
        images: ['P4002.jpg',],
        familia: '4',
        variant: [
            { id: '01', marca: '23', nombre: 'Envase 850ml ', precio: 27.5 },

        ]
    },
    {
        id: 23,
        codprod: 'P4015',
        nombre: 'Pastilla Aromatizante para ba\u00F1o ',
        descrip: 'Aromas varian seg\u00FAn disponibilidad',
        marcas: ['25'],
        marsel: '25',
        images: ['P4015.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '25', nombre: 'Pieza ', precio: 12 },
        ]
    },
    {
        id: 24,
        codprod: 'P4016',
        nombre: 'Pastilla de cloro Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4161','4162'],
        marsel: '4161',
        tipo: 'Presentaci\u00F3n',
        images: ['P4016.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4161', nombre: '1 Kilo', precio: 135 },
            { id: '02', marca: '4162', nombre: '1 Pieza', precio: 4 },

        ]
    },
    {
        id: 26,
        codprod: 'P4017',
        nombre: 'Aceite para MOP Bio-Comercial',
        descrip: 'la Imagen representa tamaños de los envases',
        marcas: ['4171'],
        marsel: '4171',
        tipo: 'Presentaci\u00F3n',
        images: ['biocomercial.jpg'],
        familia: '4',
        variant: [
            { id: '01', marca: '4171', nombre: 'Envase de 20 litros ', precio: 1025 },
            { id: '02', marca: '4171', nombre: 'Envase de 5 litros', precio: 288.5 },
            { id: '03', marca: '4171', nombre: 'Envase 1 litro ', precio: 56 },
        ]
    },


    {
        id: 25,
        codprod: 'P7001',
        nombre: 'Tapete para urinal',
        descrip: 'El color puede varias',
        marcas: ['7011', '7012'],
        marsel: '7011',
        tipo: 'Tipo',
        images: ['P7001.jpg', 'P7001-2.jpg'],
        familia: '7',
        variant: [
            { id: '01', marca: '7011', nombre: 'Liso', precio: 19 },
            { id: '02', marca: '7012', nombre: 'Anti-Salpicaduras', precio: 79 },

        ]
    },

];










export const Marcas = [//las marcas deben estar sincronizadas con el servidor
    { key: '1', value:'Dalia'},
    { key: '2', value:'Lis-cia'},
    { key: '3', value:'Petalo'},
    { key: '4', value:'Prueba'},
    { key: '5', value:'Tork'},
    { key: '6', value:'Premier'},
    { key: '7', value:'Elite'},
    { key: '8', value: 'Fapsa' },
    { key: '9', value: 'Mr. Paper' },
    { key: '10', value: 'Sedita' },
    { key: '11', value: 'Marli' },
    { key: '12', value: 'Max' },
    { key: '13', value: 'Lys' },
    { key: '14', value: 'Adorable' },
    { key: '15', value: 'Mia' },
    { key: '16', value: 'Velvet' },
    { key: '17', value: 'Regio' },
    { key: '18', value: 'Kleneex' },
    { key: '19', value: 'Sanita' },
    { key: '20', value: 'Soft' },
    { key: '21', value: 'Bio-Comercial' },
    { key: '22', value: 'Suavel' },
    { key: '23', value: 'Alen' },
    { key: '24', value: 'Colgate-Palmolive' },
    { key: '25', value: 'Wiese' },
    { key: '26', value: 'Dart' },
    { key: '27', value: 'Jaguar' },
    { key: '28', value: 'Segosan' },
    { key: '4041', value: 'Al 6%' },
    { key: '4042', value: 'Al 13% Cloro Base' },
    { key: '4051', value: 'Lavanda' },
    { key: '4052', value: 'Mar Fresco' },
    { key: '4053', value: 'Lima Limon' },
    { key: '4054', value: 'Floral' },
    { key: '4055', value: 'Canela' },
    { key: '4056', value: 'Manzana' },
    { key: '4057', value: 'Naranja' },
    { key: '4058', value: 'Kiwi' },
    { key: '4059', value: 'Pasific' },
    { key: '40510', value: 'Cereza' },
    { key: '4061', value: 'Dove' },
    { key: '4062', value: 'Kiwi' },
    { key: '4063', value: 'Orquideas' },
    { key: '4064', value: 'Cereza' },
    { key: '4065', value: 'Naranja' },
    { key: '4071', value: 'Persil' },
    { key: '4072', value: 'Mas Blanco' },
    { key: '4073', value: 'Mas Negro' },
    { key: '4074', value: 'Mas Color' },
    { key: '4075', value: 'Ariel' },
    { key: '4076', value: 'Roma' },
    { key: '4077', value: 'Zote' },
    { key: '4078', value: 'Vel Rosita' },
    { key: '4079', value: 'Lirio' },
    { key: '4081', value: 'Lilas' },
    { key: '4082', value: 'Tango' },
    { key: '4083', value: 'Bambu' },
    { key: '4084', value: 'Momentos Magicos' },
    { key: '4085', value: 'Elegance' },
    { key: '4086', value: 'Bebe' },
    { key: '4091', value: 'Liquido' },
    { key: '4092', value: 'Gel' },
    { key: '4101', value: 'Tipo Windex' },
    { key: '4111', value: 'Base Alcohol' },
    { key: '4112', value: 'Con aceite de pino' },
    { key: '4131', value: 'Eternity' },
    { key: '4132', value: 'CK ONE' },
    { key: '4133', value: 'Polo Sport' },
    { key: '4134', value: 'Carolina Herrera' },
    { key: '4141', value: 'Ligero' },
    { key: '4142', value: 'Pesado' },
    { key: '4161', value: 'Kilo' },
    { key: '4162', value: 'Pieza' },
    { key: '3011', value: 'Tipo Salvo' },
    { key: '3021', value: 'Ace' },
    { key: '7011', value: 'Liso' },
    { key: '7012', value: 'Anti-Salpicaduras' },
    { key: '4171', value: 'Aceite' },
    { key: '2011', value: 'FS' },
    { key: '2051', value: 'Negra' },
    { key: '2052', value: 'Verde' },
    { key: '2053', value: 'Azul' },
    { key: '2054', value: 'Naranja' },
    { key: '2061', value: 'Alta' },
    { key: '2062', value: 'Baja' },

    ]
export const Familias = [
    { key: '1', value:'Higienicos y toallas'},
    { key: '2', value:'Plasticos y Desechables'},
    { key: '3', value:'Detergentes y Jabones'},
    { key: '4', value:'Desinfetantes y Aromatizantes'},
    { key: '5', value:'Cestos y Contnedores'},
    { key: '6', value:'Abarrotes'},
    { key: '7', value:'Despachadores'},
    { key: '8', value:'Jarcieria'},
    ]


